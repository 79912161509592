<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <!-- <img :src="productImg1" :alt="altText" class="imgItem1" /> -->
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <h1>Chính sách bảo mật</h1>
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import productImg1 from "@/page/tongshiimportacion/components/img/5_1.png";
import productImg2 from "@/page/wisementpresta/components/img/4_2.png";

export default {
  data() {
    return {
      productImg1: productImg1,
      productImg2: productImg2,
      text:`Theo Luật bảo vệ dữ liệu cá nhân của Liên bang (“LFPDPPP”) và các quy định của Luật cũng như các quy định pháp lý khác áp dụng tại Việt Nam (sau đây gọi chung là Luật), chúng tôi xuất bản để bạn xem xét hướng dẫn về các quy định pháp lý và xử lý thông tin của bạn một cách sáng suốt. Tuyên bố về quyền riêng tư để đảm bảo quyền riêng tư cá nhân và quyền tự chủ về thông tin của bạn.

1. Danh tính và địa chỉ của người thu thập dữ liệu

Vui lòng đọc kỹ Chính sách quyền riêng tư này trước khi sử dụng Dịch vụ của chúng tôi và nếu bạn không đồng ý với Chính sách quyền riêng tư này hoặc bất kỳ phần nào trong đó, vui lòng không truy cập bất kỳ phần nào trong Nền tảng và Dịch vụ của chúng tôi.

2. Mục đích thu thập và lưu trữ dữ liệu cá nhân

(1) Mục đích chính của việc thu thập thông tin của bạn là cung cấp cho bạn những trải nghiệm và dịch vụ an toàn hơn, nhanh hơn, hiệu quả hơn và linh hoạt hơn, đồng thời giúp chúng tôi cải thiện và tinh chỉnh một cách hiệu quả các dịch vụ chúng tôi cung cấp.

(2) Xác minh danh tính của bạn hoặc bất kỳ thông tin được ủy quyền nào khác mà bạn cung cấp cho chúng tôi.

(3) Phân tích và đánh giá năng lực của hồ sơ đã nộp trước khi cho vay (bao gồm nhưng không giới hạn ở điểm tín dụng, đánh giá tín dụng và phân tích khả năng trả nợ).

(4) Để phát hiện, ngăn chặn và chống gian lận, rửa tiền và/hoặc bất kỳ hành vi sử dụng bất hợp pháp nào khác đối với các dịch vụ của chúng tôi.

(5) Cung cấp hệ thống thanh toán và phương thức thanh toán cho sản phẩm bạn mua hoặc dịch vụ bạn ký hợp đồng

(6) Quản lý, phản hồi và giải quyết mọi vấn đề do người dùng nêu ra, bao gồm nhưng không giới hạn tất cả các thắc mắc, khiếu nại và/hoặc khiếu nại nảy sinh trong quá trình sử dụng.

(7) Cung cấp hỗ trợ cho các dịch vụ người dùng.

(8) Duy trì sự ổn định của dịch vụ.

(9) Ghi lại và giải quyết các vấn đề về dịch vụ.

(10) Tuân thủ các nghĩa vụ pháp lý địa phương và các yêu cầu hạn chế thông tin.

(11) Tuân thủ các yêu cầu của luật pháp và quy định hiện hành của địa phương.

(12) Các yếu tố nhận dạng khuôn mặt trong dữ liệu sinh trắc học khuôn mặt cá nhân để xác nhận danh tính thực sự của bạn nhằm ngăn chặn gian lận và mạo danh thông tin cá nhân, đồng thời cho phép chúng tôi ký kết các tài liệu hợp đồng cần thiết.

3. Thông tin về dữ liệu cá nhân được thu thập và xử lý

Khi yêu cầu khoản vay, người dùng phải đồng ý cấp cho chúng tôi các quyền sau từ thiết bị của họ:

(1) Thông tin cá nhân cơ bản

Chúng tôi thu thập thông tin bạn cung cấp trong Dữ liệu nhận dạng của bạn, bao gồm tên, giới tính, ngày sinh, trình độ học vấn, địa chỉ email, nhà riêng và nơi cư trú. Thông tin này chỉ được sử dụng để đánh giá tính xác thực của khoản vay và dự đoán rủi ro và chúng tôi sẽ không tiết lộ thông tin của bạn nếu không có sự cho phép của bạn. Bạn cho phép tải lên thông tin cá nhân cơ bản được mã hóa lên máy chủ của chúng tôi.

(2) Người liên hệ

Chúng tôi sẽ tải lên và chuyển dữ liệu danh sách liên hệ của bạn (bao gồm tên và số điện thoại) đến máy chủ của chúng tôi; tên và số điện thoại được tải lên và chuyển giao sẽ được sử dụng cho mục đích quản lý rủi ro và chống lừa đảo bằng cách gửi dữ liệu danh sách liên hệ đến máy chủ của chúng tôi kiểm tra. Chúng tôi sẽ không bao giờ chia sẻ dữ liệu với bên thứ ba mà không có sự cho phép của bạn. Chúng sẽ chỉ được thu thập khi có sự đồng ý của bạn.

(3) Máy ảnh

Để cung cấp một cách đơn giản và nhanh chóng để lấy ID của bạn nhằm mang lại cho bạn trải nghiệm không rắc rối, vui lòng cho phép truy cập vào máy ảnh của ứng dụng

(4) Tin nhắn SMS

Khi bạn đăng ký tài khoản, chúng tôi sẽ gửi cho bạn mã xác minh qua tin nhắn văn bản để đảm bảo rằng bạn đang thực sự làm việc. Để hoàn tất đăng ký nhanh chóng, chúng tôi cần có sự cho phép của bạn để đọc nội dung tin nhắn của bạn để chúng tôi có thể nhanh chóng điền mã xác minh đăng ký.

Khi bạn đăng ký khoản vay, chúng tôi sẽ xin phép SMS và thu thập thông tin SMS của bạn, bao gồm nội dung, người gửi và trạng thái đọc của SMS, để xây dựng hồ sơ tài chính của bạn, xác định và phân tích hành vi của bạn cũng như rủi ro của nhiều khoản vay, để để đánh giá xem bạn có đủ điều kiện vay vốn hay không. Các khoản vay có thể được xử lý, điều này giúp ngăn chặn gian lận, chúng tôi chỉ kiểm soát SMS tài chính (SMS tài chính 4 chữ số). Dữ liệu SMS của bạn sẽ được tải lên máy chủ của họ một cách an toàn bằng mã hóa SSL. Dữ liệu SMS được tải lên và truyền đi sẽ được sử dụng để quản lý rủi ro và kiểm soát chống gian lận. Sau khi hoàn tất dịch vụ cho vay, dữ liệu sẽ bị xóa khỏi máy chủ của chúng tôi và chúng tôi sẽ chỉ làm như vậy như vậy với sự đồng ý của bạn. Chúng tôi sẽ không chia sẻ dữ liệu này với bên thứ ba mà không có sự cho phép của bạn.

(5) Vị trí

Chúng tôi thu thập thông tin vị trí (GPS, IP) từ thiết bị của bạn để giảm thiểu rủi ro liên quan đến tài khoản của bạn. Thông tin này giúp bạn đưa ra quyết định tốt hơn về rủi ro thông tin và hợp lý hóa quy trình Biết khách hàng của bạn (KYC).

Dữ liệu sẽ được bảo vệ bằng mã hóa và sẽ được tải lên qua kết nối an toàn và được lưu trữ trên máy chủ của chúng tôi.

Thông tin hồ sơ trong hồ sơ là cần thiết để phát hiện gian lận và quản lý rủi ro, thu thập và kiểm soát tốt hơn một số thông tin nhất định về điện thoại của bạn, chẳng hạn như: số nhận dạng thiết bị do người dùng đặt trên điện thoại của bạn, vì thông tin này giúp chúng tôi nhận dạng duy nhất Người dùng để chúng tôi có thể phát hiện và ngăn chặn các thiết bị trái phép sử dụng tên của bạn.

(6)Thông tin thiết bị

Loại thiết bị, dữ liệu thiết bị (IMEI), dữ liệu vị trí, dữ liệu giao dịch (chẳng hạn như danh sách các ứng dụng đã cài đặt), v.v. Chúng sẽ được sử dụng để xác định các hoạt động cho vay và rủi ro khác nhau cũng như đánh giá liệu khoản vay có được phê duyệt hoặc xử lý hay không. Bạn cho phép tải thông tin thiết bị bạn cung cấp lên máy chủ được mã hóa của chúng tôi. Ngoài ra, chúng tôi sẽ không chia sẻ hoặc chia sẻ thông tin của bạn với bên thứ ba mà không có sự cho phép của bạn.

(7) Lưu trữ dữ liệu

Chúng tôi yêu cầu quyền lưu trữ thông tin để đảm bảo rằng sao kê tài khoản khoản vay của người dùng được tải xuống và lưu trữ an toàn trên điện thoại của người dùng, mặc dù người dùng tất nhiên có thể tải lên một số hình ảnh hoặc tệp theo ý mình để tăng tốc độ phê duyệt khoản vay và giải ngân, dữ liệu này sẽ được được mã hóa bảo vệ và lưu trữ trên máy chủ của chúng tôi thông qua một liên kết an toàn. Sau khi dịch vụ cho vay hoàn tất, dữ liệu sẽ bị xóa khỏi máy chủ của chúng tôi.

4. Dữ liệu cá nhân nhạy cảm được thu thập

Chúng tôi không thu thập dữ liệu cá nhân nhạy cảm từ chủ sở hữu. Điều quan trọng cần lưu ý là các yếu tố trên thuộc danh mục dữ liệu cá nhân.

5. Hướng dẫn đặc biệt

Tất cả dữ liệu trước đó sẽ không được lưu vĩnh viễn và sẽ bị xóa sau khi sử dụng.

Bằng cách tạo tài khoản trên ứng dụng di động của chúng tôi và chấp nhận tuyên bố về quyền riêng tư của chúng tôi, bạn đồng ý xử lý dữ liệu cá nhân của mình cho các mục đích đã nêu ở trên.

Bạn có thể rút lại sự đồng ý của mình đối với việc xử lý dữ liệu cá nhân của mình bất cứ lúc nào, trong trường hợp đó, chúng tôi chỉ có thể tiếp tục xử lý theo các trường hợp ngoại lệ được nêu trong LFPDPPP.

Cuối cùng, chúng tôi thông báo cho bạn rằng chúng tôi sẽ lưu giữ dữ liệu cá nhân của bạn trong khoảng thời gian cần thiết để tuân thủ các nghĩa vụ pháp lý của chúng tôi và miễn là không có khoảng thời gian theo luật định nào được quy định để bắt đầu hoặc phản hồi bất kỳ thủ tục tố tụng tư pháp nào liên quan đến mối quan hệ pháp lý được thiết lập và tạo ra giữa chúng ta. Khi các mục đích mà chúng tôi thu thập, xử lý và lưu trữ dữ liệu của bạn đã được đáp ứng, chúng tôi sẽ tiếp tục chặn chúng để hủy sau này.

6. Thủ tục thực hiện các quyền truy cập, cải chính, hủy bỏ và phản đối (quyền ARCO)

Bạn có quyền biết dữ liệu cá nhân nào chúng tôi lưu giữ về bạn, mục đích chúng tôi sử dụng dữ liệu đó và các điều kiện mà chúng tôi cung cấp dữ liệu đó (quyền truy cập). Tương tự như vậy, bạn có quyền yêu cầu sửa đổi dữ liệu cá nhân của mình (chỉnh sửa) nếu dữ liệu đó lỗi thời, không chính xác hoặc không đầy đủ; và xóa dữ liệu đó khỏi hồ sơ của chúng tôi khi chúng tôi cho rằng dữ liệu đó chưa được sử dụng theo các nguyên tắc, nghĩa vụ và các nghĩa vụ được quy định trong các quy định hoặc xóa khỏi cơ sở dữ liệu (hủy bỏ) và phản đối việc sử dụng dữ liệu cá nhân của bạn cho một mục đích cụ thể (phản đối).

Để tìm hiểu về các thủ tục và yêu cầu thực hiện các quyền ARCO nêu trên, bạn có thể liên hệ với Bộ phận quyền riêng tư của chúng tôi theo địa chỉ email. Họ sẽ xử lý các yêu cầu thực hiện các quyền này và giải quyết mọi câu hỏi mà bạn có thể có liên quan đến việc xử lý dữ liệu của mình.

Trong một số trường hợp nhất định, bạn có thể rút lại sự đồng ý đối với việc xử lý dữ liệu cá nhân của mình. Tuy nhiên, điều quan trọng là bạn phải lưu ý rằng không phải trong mọi trường hợp chúng tôi đều có thể đáp ứng yêu cầu của bạn hoặc chấm dứt sử dụng ngay lập tức vì chúng tôi có thể cần tiếp tục xử lý dữ liệu cá nhân của bạn do một số nghĩa vụ pháp lý nhất định. Tương tự như vậy, bạn nên cân nhắc rằng, vì một số mục đích, việc rút lại sự đồng ý của bạn sẽ có nghĩa là chúng tôi sẽ không thể cung cấp cho bạn các dịch vụ mà bạn yêu cầu từ chúng tôi nữa hoặc mối quan hệ của bạn với chúng tôi sẽ chấm dứt. Để rút lại sự đồng ý của bạn, bạn phải gửi yêu cầu qua email và cung cấp tên, thông tin liên hệ của chủ sở hữu và bao gồm cả giấy tờ tùy thân chính thức.
7. Rò rỉ dữ liệu và bảo vệ an ninh

Dữ liệu chúng tôi thu thập để xử lý và lưu trữ được mã hóa và kiểm soát an toàn trong cơ sở dữ liệu của chúng tôi và sẽ không được tiết lộ cho bên thứ ba có chứa thông tin nhận dạng cá nhân (trừ khi luật pháp yêu cầu) và chúng tôi thường sẽ chỉ liên lạc ẩn danh với các bên thứ ba. Chia sẻ thông tin của bạn. Bạn hiểu và đồng ý rằng chúng tôi có thể chuyển giao và tiết lộ thông tin của bạn nếu cần thiết hoặc phù hợp cho các mục đích kinh doanh sau:

(1) Yêu cầu pháp lý:

Chúng tôi thực sự tin rằng việc tiết lộ dữ liệu cá nhân của bạn là cần thiết để:

- Tuân thủ các nghĩa vụ pháp lý.

- Để bảo vệ và bảo vệ quyền hoặc tài sản của chúng tôi.

- Tránh các trách nhiệm pháp lý khác.

(2) Yêu cầu kiểm toán:

Dựa trên đánh giá rủi ro hoặc các nhu cầu kinh doanh khác, mọi thông tin cá nhân về bạn được đề cập trong Chính sách quyền riêng tư này có thể được tiết lộ cho:

- Bất kỳ nhân viên/đối tác kinh doanh/nhà cung cấp nào của chúng tôi, bao gồm các đại lý/nhà thầu/nhà cung cấp dịch vụ/bên thứ ba hợp pháp có liên quan cung cấp các dịch vụ hành chính, viễn thông, thanh toán, quản lý, kiểm toán hoặc các dịch vụ khác cho chúng tôi.

- Bất kỳ cơ quan báo cáo tín dụng/cơ quan thu nợ và/hoặc các cơ quan tín dụng có liên quan.

- Nhân viên hoặc chuyên gia tư vấn của chúng tôi, bao gồm kiểm toán viên, cố vấn pháp lý và/hoặc các loại chuyên gia tư vấn khác.

(3) Nhà cung cấp dịch vụ:

- Chúng tôi có thể thuê các công ty và cá nhân bên thứ ba để tạo điều kiện thuận lợi cho Dịch vụ của chúng tôi (nhà cung cấp dịch vụ như bộ xử lý thanh toán bên thứ ba), thay mặt chúng tôi cung cấp Dịch vụ, thực hiện các dịch vụ liên quan đến Dịch vụ hoặc hỗ trợ chúng tôi phân tích việc bạn sử dụng dịch vụ của chúng tôi. Dịch vụ .

- Các bên thứ ba này chỉ có quyền truy cập vào Dữ liệu cá nhân của bạn để thực hiện các nhiệm vụ này thay mặt chúng tôi và có nghĩa vụ không tiết lộ hoặc sử dụng dữ liệu đó cho bất kỳ mục đích nào khác.

8. Điều kiện và thủ tục sửa đổi tuyên bố chính sách quyền riêng tư

Tuyên bố về quyền riêng tư này có thể được sửa đổi, thay đổi hoặc cập nhật để tuân thủ các yêu cầu pháp lý mới. Nhu cầu của chúng tôi đối với các sản phẩm hoặc dịch vụ mà chúng tôi cung cấp; các biện pháp bảo mật của chúng tôi; những thay đổi trong mô hình kinh doanh của chúng tôi hoặc vì các lý do khác. Ngay cả sau khi chấp nhận Tuyên bố về quyền riêng tư, Chủ sở hữu vẫn có thể xem lại Tuyên bố về quyền riêng tư bất kỳ lúc nào trên Trang web hoặc Ứng dụng của chúng tôi.

9. Đồng ý

Bằng cách đăng ký vào ứng dụng của chúng tôi, bạn đồng ý rằng các điều khoản của Tuyên bố về quyền riêng tư này cấp cho bạn sự đồng ý miễn phí, cụ thể, đầy đủ thông tin và rõ ràng đối với việc xử lý dữ liệu cá nhân của bạn.`,

      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: "";
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  /* padding-top: 40px; */
  padding-bottom: 20px;
  height: 5300px;
  background-color: "#FFFFFF";
  border-radius: 32px;
  margin: auto;
  margin-top: -50px;
  transform: scale(87%,95%);
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  /* margin-top: 50px; */
 margin-left: 490px;
 color: #333333;
 font-size: 40px;
 background-image: url(./components/img/Rectangle1.png);
 background-position:0 40px;
 background-repeat: no-repeat;
/* border-bottom: 5px solid #04358A; */
display: inline-block
}
.textBox{
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
</style>